@charset "utf-8";
@media print {
  /* reset */
  body,
  div,
  dl,
  dt,
  dd,
  ul,
  ol,
  li,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  pre,
  code,
  form,
  fieldset,
  legend,
  textarea,
  p,
  blockquote,
  th,
  td,
  input,
  select,
  button {
    margin: 0;
    padding: 0;
  }
  fieldset,
  img {
    border: 0 none;
  }
  dl,
  ul,
  ol,
  menu,
  li {
    list-style: none;
  }
  blockquote,
  q {
    quotes: none;
  }
  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: "";
    content: none;
  }
  input,
  select,
  textarea,
  button {
    vertical-align: middle;
  }
  input::-ms-clear {
    display: none;
  }
  button {
    border: 0 none;
    background-color: transparent;
    cursor: pointer;
  }
  body {
    overflow-x: auto;
    min-width: 1290px;
    background: #fff;
  }
  body,
  th,
  td,
  input,
  select,
  textarea,
  button {
    font-size: 12px;
    line-height: 1.5;
    font-family: "Noto Sans KR", AppleSDGothicNeo-Regular, "Malgun Gothic", "맑은 고딕", sans-serif;
    color: #555;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: none;
  }
  a {
    color: #555;
    text-decoration: none;
  }
  /* a:active, a:hover{text-decoration:underline} */
  a:active {
    background-color: transparent;
  }
  address,
  caption,
  cite,
  code,
  dfn,
  em,
  var {
    font-style: normal;
    font-weight: normal;
  }

  /* global */
  #kakaoIndex {
    overflow: hidden;
    position: absolute;
    left: -9999px;
    width: 0;
    height: 1px;
    margin: 0;
    padding: 0;
  }
  .ir_pm {
    display: block;
    overflow: hidden;
    font-size: 0;
    line-height: 0;
    text-indent: -9999px;
  }
  .ir_caption {
    overflow: hidden;
    width: 1px;
    font-size: 1px;
    line-height: 0;
    text-indent: -9999px;
  }
  .screen_out {
    overflow: hidden;
    position: absolute;
    width: 0;
    height: 0;
    line-height: 0;
    text-indent: -9999px;
  }
  .show {
    display: block;
  }
  .hide {
    display: none;
  }
}
/* layout */
.box_print .page_print {
  width: 994px;
  padding: 48px 48px;
  page-break-after: always;
}

/* -- 상단고정 발주 번호 -- */
.box_print .area_number {
  text-align: right;
}
.box_print .badge_number {
  display: inline-block;
  height: 30px;
  padding: 0 20px;
  border-radius: 16px;
  border: 1px solid #bbb;
  line-height: 31px;
  color: #555;
  vertical-align: top;
}
.box_print .badge_number .tit_number {
  display: inline-block;
  padding-right: 13px;
  font-size: 15px;
  font-weight: 500;
  vertical-align: top;
}
.box_print .badge_number .txt_number {
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  vertical-align: top;
}
.box_print .head_print .txt_print {
  display: block;
  margin-top: -32px;
  padding: 6px 0 7px;
  font-size: 13px;
  line-height: 19px;
  color: #555;
  letter-spacing: -0.025em;
}
.box_print .head_print .tit_print {
  font-size: 28px;
  line-height: 41px;
  color: #555;
  letter-spacing: -0.025em;
}

/* -- 컨텐츠 -- */
.box_print .cont_print {
  position: relative;
  padding-top: 21px;
}
.area_print + .area_print {
  margin-top: 40px;
}

/* -- 프린트시 스타일 재설정 -- */
@media print {
  .page_print {
    margin: 0;
    padding-bottom: 0;
    border: 0 none;
  }
  .area_btn {
    display: none;
  }
}

/* -- 컨텐츠 상세 -- */
/* 공통 */
.box_print .bar_info {
  display: block;
  width: 46px;
  border-top: 1px solid #222;
}
.box_print .info_left {
  float: left;
  width: 426px;
}
.box_print .info_right {
  float: right;
  width: 524px;
}

/* -- OrderPrintInfo -- */
/* 정보영역 */
.box_print .area_info {
  overflow: hidden;
  padding-bottom: 16px;
}
.box_print .area_info dl {
  float: left;
  overflow: hidden;
  width: 213px;
  margin-top: 24px;
  font-size: 13px;
  line-height: 19px;
}
.box_print .area_info dl:nth-of-type(n + 3) {
  margin-top: 36px;
}
.box_print .area_info dt {
  font-weight: normal;
  color: #222;
}
.box_print .area_info dd {
  float: left;
  padding-top: 8px;
  color: #555;
}
.box_print .area_info dd + dd {
  padding-left: 8px;
}
/* 정보영역_합계금액 */
.box_print .box_total {
  width: 100%;
  margin-top: 25px;
  padding: 19px 32px 25px;
  border: 1px solid #ffcd00;
  background-color: #fffdf6;
  box-sizing: border-box;
}
.box_print .box_total .head_total {
  overflow: hidden;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: -0.05em;
}
.box_print .box_total .head_total .tit_total {
  float: left;
  font-weight: normal;
  color: #222;
}
.box_print .box_total .head_total .txt_total {
  float: right;
  color: #888;
}
.box_print .box_total .body_total {
  padding-top: 25px;
  text-align: right;
}
.box_print .box_total .body_total .txt_total {
  display: inline-block;
  padding-top: 15px;
  font-size: 13px;
  line-height: 19px;
  color: #222;
  vertical-align: top;
}
.box_print .box_total .body_total .txt_price {
  display: inline-block;
  padding-left: 24px;
  font-size: 26px;
  line-height: 38px;
  color: #222;
  vertical-align: top;
}
.box_print .box_total .body_total .num_price {
  display: inline-block;
  padding: 9px 0 0 8px;
  font-size: 18px;
  line-height: 27px;
  color: #222;
  vertical-align: top;
}

/* -- OrderPrintEstimate -- */
/* 테이블 공통 */
.box_print .tbl_print {
  overflow: hidden;
  border-top: 1px solid #222;
}
.box_print .tbl_print table {
  width: 100%;
  border: 0 none;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
}
.box_print .tbl_print + .tbl_print {
  margin-top: 24px;
  border-top: 1px solid #bbb;
}
/* 테이블 리스트 타입 */
.box_print .tbl_list thead th {
  height: 48px;
  padding: 0 12px;
  border-bottom: 1px solid #e1e1e1;
  font-weight: normal;
  font-size: 15px;
  background-color: #fbfbfb;
  color: #222;
  text-align: center;
}
.box_print .tbl_list tbody td {
  padding: 12px 12px 13px;
  border-bottom: 1px solid #e1e1e1;
  font-size: 15px;
  line-height: 23px;
  color: #555;
  text-align: center;
  word-wrap: break-word;
  word-break: keep-all;
}
.box_print .tbl_list thead th:last-child,
.box_print .tbl_list tbody td:last-child {
  padding-right: 32px;
}
.box_print .tbl_list .align_right {
  text-align: right;
}
.box_print .tbl_list .align_left {
  text-align: left;
}

/* file */
.box_print .file_view {
  padding: 0;
}
.box_print .file_view .btn_file {
  display: block;
  width: 100%;
  padding-left: 0;
  border: 0 none;
  font-size: 15px;
  line-height: 18px;
  text-indent: inherit;
  outline: none;
  text-align: left;
}
.box_print .file_view .ico_purchase {
  display: none;
}
.box_print .file_view .txt_file {
  display: block;
  font-size: 15px;
  line-height: 22px;
  color: #4871ed;
  text-decoration: underline;
  word-wrap: break-word;
  word-break: keep-all;
}

/* -- OrderPrintTotal -- */
/* 박스 합계 정보 */
.box_print .group_total {
  overflow: hidden;
  padding: 0 0 56px;
  page-break-inside: avoid;
}
.box_print .group_total .box_total {
  float: left;
  width: 270px;
  margin-left: 17px;
  padding: 20px 24px;
  border: 1px solid #e1e1e1;
}
.box_print .group_total .box_total:first-child {
  margin-left: 0;
}
.box_print .group_total .total_head {
  overflow: hidden;
}
.box_print .group_total .total_head .tit_total {
  float: left;
  font-weight: normal;
  font-size: 12px;
  color: #222;
}
.box_print .group_total .total_head .count_total {
  float: right;
  font-size: 13px;
  line-height: 19px;
  color: #888;
}
.box_print .group_total .total_head .tit_count {
  float: left;
  padding-right: 8px;
}
.box_print .group_total .total_head .txt_bar {
  float: left;
  width: 1px;
  height: 10px;
  margin: 4px 0 5px;
  border-right: 1px solid #e1e1e1;
}
.box_print .group_total .total_head .txt_count {
  overflow: hidden;
  padding-left: 8px;
}
.box_print .group_total .txt_total {
  display: block;
  padding-top: 6px;
  font-size: 20px;
  line-height: 29px;
  color: #555;
  text-align: right;
}
.box_print .group_total .txt_total .txt_util {
  display: inline-block;
  margin-top: 6px;
  font-size: 14px;
  line-height: 20px;
  vertical-align: top;
}

/* -- orderPrintTip -- */
.box_print .area_tip {
  page-break-inside: avoid;
}
/* 테이블 뷰 타입 */
.box_print .tbl_view tbody th {
  padding: 13px 24px;
  font-size: 15px;
  border-bottom: 1px solid #e1e1e1;
  font-weight: normal;
  background-color: #fbfbfb;
  color: #222;
  vertical-align: top;
  text-align: left;
}
.box_print .tbl_view tbody td {
  padding: 13px 24px;
  font-size: 15px;
  border-left: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  line-height: 22px;
  color: #555;
}
.box_print .tbl_view tbody td:first-child {
  border-left: 0 none;
}
.box_print .tbl_view .align_top {
  vertical-align: top;
}
.box_print .tbl_view .align_top p + p {
  margin-top: 8px;
}
.box_print .tbl_view .no_padding {
  padding: 0;
}
/* 하단 합계 정보 */
.box_print .list_total {
  padding: 18px 32px 15px;
  line-height: 22px;
  background-color: #fbfbfb;
  color: #555;
}
.box_print .list_total dt {
  float: left;
  font-size: 15px;
  letter-spacing: -0.05em;
}
.box_print .list_total dd {
  overflow: hidden;
  font-size: 15px;
  text-align: right;
  letter-spacing: -0.03em;
}
.box_print .list_total dd + dt,
.box_print .list_total dd + dt + dd {
  padding-top: 10px;
}
.box_print .list_total .dt_total,
.box_print .list_total .dd_total {
  margin-top: 17px;
  padding-top: 14px;
  border-top: 1px solid #e1e1e1;
  color: #555;
}
.box_print .list_total .dt_total *,
.box_print .list_total .dd_total * {
  display: inline-block;
  vertical-align: top;
}
.box_print .list_total .dt_total {
  padding-top: 16px;

  font-size: 15px;
  line-height: 22px;
  font-weight: 500;
}
.box_print .list_total .txt_small {
  margin-left: 8px;
  padding: 2px 0 1px;
  font-size: 13px;
  line-height: 19px;
  color: #888;
}
.box_print .list_total .dd_total {
}
.box_print .list_total .txt_total {
  padding: 4px 0 4px;
  font-size: 13px;
  line-height: 19px;
}
.box_print .list_total .txt_korean {
  margin-left: 24px;
  font-size: 18px;
  line-height: 27px;
}
.box_print .list_total .txt_price {
  margin-left: 8px;
  padding: 2px 0 3px;
  font-size: 15px;
  line-height: 22px;
}

/* 하단 알림 문구 */
/* .box_print .desc_alert{padding:13px 0;border:1px solid #F8626C;font-size:15px;line-height:22px;background-color:#FEF7F7;color:#F8626C;text-align:center} */

/* -- OrderPrintTerm -- */
/* 약관 */
.box_print .area_term {
  font-size: 15px;
  line-height: 22px;
  color: #555;
  letter-spacing: -0.05em;
}
.box_print .tit_term {
  display: block;
  margin: 20px 0 4px;
  font-weight: normal;
}
.box_print .tit_term:first-child {
  margin-top: 0;
}
.box_print .list_term > li {
  padding-left: 20px;
  text-indent: -20px;
}
.box_print .list_term > li:last-child .list_term2 {
  margin-bottom: 0;
}
.box_print .list_term2 {
  margin: 6px 0;
}
.box_print .list_term2 > li {
  padding-left: 15px;
  text-indent: -15px;
}
.box_print .desc_term {
  font-size: 15px;
  line-height: 22px;
  color: #555;
  letter-spacing: -0.05em;
}
.box_print .list_term + .desc_term {
  margin-top: 12px;
}

/* 서약서 하단 정보영역 */
.box_print .box_oath {
  margin: 48px 0 40px;
  padding: 40px 56px;
  border: 1px solid #e1e1e1;
  border-top: 1px solid #222;
}
.box_print .box_oath .bar_info {
  margin: 24px 0;
}
.box_print .box_oath .line_info {
  overflow: hidden;
}
.box_print .box_oath dl {
  float: left;
  font-size: 15px;
  line-height: 22px;
}
.box_print .box_oath dl + dl {
  padding-left: 56px;
}
.box_print .box_oath dl:last-child {
  float: none;
  overflow: hidden;
}
.box_print .box_oath dt {
  color: #222;
}
.box_print .box_oath dd {
  padding-top: 8px;
  color: #555;
  word-wrap: break-word;
  word-break: keep-all;
}
.box_print .box_oath + .desc_term {
  padding: 2px 0 141px;
}
/* 버튼 */
.box_print .area_btn {
  padding-bottom: 49px;
  text-align: center;
}
.box_print .btn_agree {
  display: inline-block;
  min-width: 120px;
  height: 40px;
  padding: 10px 32px;
  border-radius: 2px;
  border: 1px solid #ffcd00;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  background-color: #ffcd00;
  color: #222;
  vertical-align: top;
}
.box_print .area_btn + .desc_term {
  padding-bottom: 53px;
}
/* 첨부파일 */
.box_print .area_file .btn_file {
  display: block;
  font-size: 15px;
  line-height: 22px;
  color: #4871ed;
  text-decoration: underline;
  text-align: left;
}
.box_print .area_file .btn_file + .btn_file {
  margin-top: 8px;
}
